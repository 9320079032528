import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {Injectable} from '@angular/core';
import {ConfirmModalComponent} from '../../components/confirm-modal/confirm-modal.component';
import {RichTextModalComponent} from '../../components/rich-text-modal/rich-text-modal.component';
import {IdentityTypes} from '../../domain/identity/identitytypes';
import {ConfirmUnmergeModalComponent} from '../../pages/identity/identity-merge/confirm-unmerge-modal.component';
import {ButtonType} from '../../domain/button/button-type';
import {Observable} from 'rxjs';
import {mergeMap, take} from 'rxjs/operators';
import {ChangeEmailModalComponent} from '../../pages/identity/detail/change-email-modal.component';
import {Service} from '../../domain/service/service-data';
import {MetadataService} from '../metadata.service';
import {EmailType} from '../../components/confirm-modal/send-quotation/confirm-send-modal.component';

export interface IInitialState {
  translatePrefix?: string;
  typeOfButton?: ButtonType;
  editorData?: string;
  idType?: IdentityTypes;
  cancelBtn?: boolean;
  class?: string;
  service?: Service;
  messageParams?: {[k: string]: any};
  readonly?: boolean;
  canClose?: boolean;
  mustAnswer?: boolean;
  emailType?: EmailType;
  confirmVisible?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ModalService {

  private defaultConfig: {[k: string]: any} = {
    backdrop: true,
    ignoreBackdropClick: true
  };

  constructor(
    private bsModalService: BsModalService,
    private metadataService: MetadataService
  ) {
    this.defaultConfig = {
      ...this.bsModalService?.config,
      backdrop: true,
      ignoreBackdropClick: true
    };
  }

  show(component: any, initialState: IInitialState = {}, config: {[k: string]: any} = {}): BsModalRef {
    return this.bsModalService.show(component,
      {...this.defaultConfig, ...{initialState}, ...config});
  }

  showWarning(initialState: IInitialState, keyboard: boolean = true): Observable<boolean> {
    const bsModalRef = this.bsModalService.show(ConfirmModalComponent,
      {...this.defaultConfig, keyboard, ...{initialState}});

    return bsModalRef.content.onClose;
  }

  showGenericWarning(): Observable<boolean> {
    return this.showWarning({translatePrefix: 'warning'});
  }

  showFileWarning(): Observable<boolean> {
    const initialState = {translatePrefix: 'fileWarning'};
    const bsModalRef = this.bsModalService.show(
      ConfirmModalComponent, {...this.defaultConfig, ...{initialState}}
    );

    return bsModalRef.content.onClose;
  }

  // Use this when we are interested in the result of a ConfirmModalComponent.
  getConfirmation(initialState: IInitialState): Observable<boolean> {
    const bsModalRef = this.bsModalService.show(
        ConfirmModalComponent, {...this.defaultConfig, ...{initialState}}
    );

    return bsModalRef.content.onClose;
  }

  getRichText(initialState: IInitialState, addMetaData: boolean = false): Observable<[boolean, string]> {
    if (addMetaData) {
      return this.metadataService.addMetadata(initialState.editorData, true).pipe(
        take(1),
        mergeMap((md) => {
        initialState.editorData = md;
        return this.showRichTextModal(initialState);
      }));
    } else {
      return this.showRichTextModal(initialState);
    }
  }

  showUnmergeIdentitiesModal(initialState: IInitialState): BsModalRef {
    return this.bsModalService.show(
      ConfirmUnmergeModalComponent, {...this.defaultConfig, ...{initialState}}
    );
  }

  getNewEmail(initialState: IInitialState): Observable<string> {
    const bsModalRef = this.bsModalService.show(
      ChangeEmailModalComponent, {...this.defaultConfig, ...{initialState}}
    );

    return bsModalRef.content.onClose;
  }

  private showRichTextModal(initialState: IInitialState): Observable<[boolean, string]> {
    const bsModalRef = this.bsModalService.show(
        RichTextModalComponent,
        {...this.defaultConfig, ...{initialState, class: 'rich-text-modal'}}
    );

    return bsModalRef.content.onClose;
  }
}
